// remap jQuery to $
(function($) {

    var $width = $(window).width();
    var $menuLink = $('.menu li');
    var slideMenu = function() {
        $menuLink.hover(function() {
            var submenu = $(this).find('.submenu');
            submenu.slideToggle('fast');
        });
    }

    var drilldownMenu = function() {

            $menuLink.on('click', function() {

                var submenu = $(this).find('.submenu');
                submenu.css('position', 'static');
                submenu.addClass('nested');
                submenu.slideToggle('fast');

                var img = $(this).find('img');

                img.toggleClass('rotate');
            });
        }
        /* trigger when page is ready */
    $(document).ready(function() {
        $(document).foundation();
        $('.slick').slick({
            centerMode: true,
            slideToShow: 3,
            nextArrow: "<i class='fa fa-chevron-right slick-next'></i>",
            prevArrow: "<i class='fa fa-chevron-left slick-prev'></i>",
            variableWidth: true,
            lazyLoad: 'progressive',
            speed: 800
        });
        // Toggle Slide on Responsive Menu
        $('.menu-icon').on(
            'click',
            function() {
                var dropdown = $('#main-nav');
                dropdown.slideToggle('fast');

            });

        // For Menu Animation. If mobile, drilldown, if not, slide.
        if ($width >= 640) {
            slideMenu();
        } else {
            drilldownMenu();
        }

        // Adds this class whenever there is a submenu
        $menuLink.has('.submenu').append('<img src="wp-content/themes/Mad-Mouse-Creative-Theme-2016/public/img/fi-play.svg" class="hide-for-medium">');
        $menuLink.has('ul').addClass('is-submenu-parent');

        $('body').fadeIn('slow');
    });

    // Window Resize Events
    $(window).resize(function() {
        // Changes Menu Animation based on Window Size.
        if ($(this).width() >= 640) {
            $menuLink.unbind('mouseenter mouseleave click');
            slideMenu();
        } else {
            $menuLink.unbind('mouseenter mouseleave click');
            drilldownMenu();
        }
    });

// the selector will match all input controls of type :checkbox
// and attach a click event handler 
$("input:checkbox").on('click', function() {
  // in the handler, 'this' refers to the box clicked on
  var $box = $(this);
  if ($box.is(":checked")) {
    // the name of the box is retrieved using the .attr() method
    // as it is assumed and expected to be immutable
    var group = "input:checkbox[name='" + $box.attr("name") + "']";
    // the checked state of the group/box on the other hand will change
    // and the current value is retrieved using .prop() method
    $(group).prop("checked", false);
    $box.prop("checked", true);
  } else {
    $box.prop("checked", false);
  }
});
	
})(window.jQuery);